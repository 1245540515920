import { spacingMap } from '../helpers';

const dummyText = `This medium-length paragraph provides more detail, suitable for sections that require a bit more explanation. It's perfect for content areas where you need to elaborate on a topic without overwhelming the reader with text.`

type Props = {
    text?: string,
    textSize?: 'auto' | 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl',
    textColor?: 'primary' | 'primary-content' | 'accent' | 'accent-content' | 'base-0' | 'base-50' | 'base-100' | 'base-content' | 'base-500' | 'base-700' | 'base-900' | 'success-content' | 'warning-content' | 'error-content' | 'info-content' | string,
    marginBottom?: '4px' | '6px' | '8px' | '12px' | '16px' | '24px' | '32px',
    marginTop?: '4px' | '6px' | '8px' | '12px' | '16px' | '24px' | '32px',
    lineHeight?: 'auto' | 'none' | 'tight' | 'normal' | 'relaxed' | 'loose',
    textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify',
    fontWeight?: 'auto' | 'hairline' | 'thin' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black',
    __juno?: any
}

export default function Paragraph({
        text = dummyText,
        textSize,
        textColor,
        marginBottom,
        marginTop,
        lineHeight,
        textAlign = 'left',
        
        fontWeight,
        __juno = {} 
      }: Props) {

    const textSizeStyles = textSize ? `text-${textSize}` : '';
    const textColorStyles = (textColor == 'none' || !textColor) ? `` : `text-${textColor}`
    const marginBottomStyles = marginBottom ? `mb-${spacingMap[marginBottom]}` : '';
    const marginTopStyles = marginTop ? `mt-${spacingMap[marginTop]}` : '';
    
    const lineHeightStyles = lineHeight ? `leading-${lineHeight}` : '';
    const fontWeightStyles = fontWeight ? `font-${fontWeight}` : '';
    const textAlignStyles = textAlign ? `text-${textAlign}` : ''; 

    const classes = `whitespace-pre-wrap ${textSizeStyles} ${textColorStyles} ${marginBottomStyles} ${marginTopStyles} ${lineHeightStyles} ${fontWeightStyles} ${textAlignStyles}`

    return (
        <p
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        >
        {text}
        </p>
    );
}


/* ignore rest */


Paragraph.stories = [
    {
        name: 'Default', 
        wrapperClasses: 'flex flex-col max-w-[480px] h-auto p-4 bg-white !bg-base-0',
        props: {

        }
    }
]


Paragraph.definitions = {
    apiName: 'Paragraph',
    displayName: 'Paragraph',
    description: 'A paragraph component with editable text content and customizable text size, color, margins, line height, font weight, and text alignment. Supports dynamic styling and interactive editing.',
    ai_instructions: 'represents <p> element. useful for pieces of copy. for short text use Text component',
    type: 'typography',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    package: null,
    propDefinitions: {
        text: {
            type: 'longString',
            default: dummyText,
            displayName: 'Text',
            editable: true,
        },
        textColor: {
            type: 'colors',
            options: ['primary', 'primary-content', 'accent', 'accent-content', 'base-0', 'base-50', 'base-100', 'base-content', 'base-500', 'base-700', 'base-900','success-content', 'warning-content', 'error-content', 'info-content'],
            displayName: 'Color',
            default: null,
            miniEditor: true,
        },
        textSize: {
            type: 'oneOf',
            options: ['auto', 'xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl'],
            default: 'auto', 
            displayName: 'Text Size',
            tile: '1/2'
        },
        textAlign: {
            type: 'oneOf',
            options: ['auto', 'left', 'right', 'center', 'justify'],
            default: 'left', 
            displayName: 'Text Alignment',
            tile: '1/2'
        }, 
        marginTop: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Top Margin',
            default: null,
            tile: '1/2'
        },
        marginBottom: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Bottom Margin',
            default: null,
            tile: '1/2'
        },
        lineHeight: {
            type: 'oneOf',
            options: ['auto', 'none', 'tight', 'normal', 'relaxed', 'loose'],
            displayName: 'Line Height',
            default: 'auto', 
            tile: '1/2'
        },
        
        
    }
};