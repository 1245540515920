
export function makeTemplates(templates) {
    if (!templates || !templates.length) {
        return [];
    }

    
    const sidebars = templates.filter(g => g.category == 'sidebars') || []
    const sidepanels = templates.filter(g => g.category == 'sidepanels') || []
    const headers = templates.filter(g => g.category == 'headers') || []
    const contentHeaders = templates.filter(g => g.category == 'content_headers') || []
    const forms = templates.filter(g => g.category == 'forms') || []
    const featurepanels = templates.filter(g => g.category == 'featurepanels') || []
    const contentblocks = templates.filter(g => g.category == 'content_blocks') || []

    
    // here we need table layouts that include table controls


    const categories = [
        { name: 'sidebars', items: sidebars, thumbnail: '', display: 'grid'},
        { name: 'sidepanels', items: sidepanels, thumbnail: '', display: 'grid'},
        { name: 'app headers', items: headers, thumbnail: '', display: 'list'},
        { name: 'content headers', items: contentHeaders, thumbnail: '', display: 'list'},
        { name: 'forms', items: forms, thumbnail: '', display: 'grid'},
        { name: 'feature panels', items: featurepanels, thumbnail: '', display: 'grid'},
        { name: 'content blocks', items: contentblocks, thumbnail: '', display: 'grid'},
    ]

  return categories;
}

export function makePrimitives(templates) {
    if (!templates || !templates.length) {
        return [];
    }
    
    const buttons = templates.filter(g => g.category == 'buttons') || []
    const inputs = templates.filter(g => g.category == 'inputs') || []
    const media = templates.filter(g => g.category == 'images') || []
    const status = templates.filter(g => g.category == 'indicators') || []
    const user = templates.filter(g => g.category == 'user') || []
    const progress = templates.filter(g => g.category == 'progress') || []
    const datetime = templates.filter(g => g.category == 'datetime') || []
    const navlinks = templates.filter(g => g.category == 'navlinks') || []
    const navigation = templates.filter(g => g.category == 'navigation') || []
    const tables = templates.filter(g => g.category == 'tables') || []
    const separators = templates.filter(g => g.category == 'separators') || []
    
    const code = templates.filter(g => g.category == 'code') || []
    const cards = templates.filter(g => g.category == 'cards') || []
    const charts = templates.filter(g => g.category == 'charts') || []
    const dialogs = templates.filter(g => g.category == 'dialogs') || []
    
    // for tables we need examples of tables (not content)
    
    const categories = [
        { name: 'buttons', items: buttons, thumbnail: '', display: 'grid'},
        { name: 'inputs', items: inputs, thumbnail: '', display: 'grid'},
        { name: 'status indicators', items: status, thumbnail: '', display: 'grid'},
        { name: 'media', items: media, thumbnail: '', display: 'grid'},
        { name: 'user', items: user, thumbnail: '', display: 'grid'},
        { name: 'progress', items: progress, thumbnail: '', display: 'grid'},
        { name: 'datepickers', items: datetime, thumbnail: '', display: 'grid'},
        { name: 'navigation', items: [...navlinks, ...navigation], thumbnail: '', display: 'grid'},
        { name: 'separators', items: separators, thumbnail: '', display: 'grid'},
        { name: 'cards', items: cards, thumbnail: '', display: 'grid'},
        { name: 'charts', items: charts, thumbnail: '', display: 'grid'},
        { name: 'code', items: code, thumbnail: '', display: 'grid'},
        { name: 'dialogs', items: dialogs, thumbnail: '', display: 'grid'},
        { name: 'tables', items: tables, thumbnail: '', display: 'list'},


    ]

  return categories;
}


